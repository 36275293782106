<template>
  <payroll-report
    :userIdProp="targetUser.user_id"
    :isAdminProp="false"
  ></payroll-report>
</template>

<script>
import { mapState } from "vuex";
import PayrollReport from "../../../admin/reports/Payroll.vue";

export default {
  name: "providerPayroll",
  components: {
    PayrollReport,
  },
  computed: {
    ...mapState({
      targetUser: (state) => state.providers.provider,
    }),
  },
};
</script>
